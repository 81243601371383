<template>
  <div class="index">
    <div class="index_box">
      <div class="index_bot_top">

      </div>
      <div class="index_canlder">
        <div class="canlder_top">
          <span style="margin-right: 30px;">活动产品名称</span>
          <el-button type="primary">批量修改</el-button>
        </div>
        <el-input v-model="price" :controls="false" type="number" style="width: 200px;" class="input" @input="changeStart"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      price: 0
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
    },
    strFormatNum(val) {
      //清除“数字”和“.”以外的字符
      val = val.replace(/[^\d.]/g, '')
      //只保留第一个. 清除多余的
      val = val.replace(/\.{2,}/g, '.')
      val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      //只能输入两个小数
      // eslint-disable-next-line
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      console.log(val)
      if (val.indexOf('.') < 0 && val != '') {
        //如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val)
      }
      return val
    },
    changeStart(value) {
      setTimeout(() => {
        this.price = this.strFormatNum(value)
      }, 30)
    }
  }
}
</script>

<style lang="scss" scoped>
.index{
  padding: 18px;
  background: #F5F6F7;
  .index_box{
    .index_bot_top{
      background: #fff;
      padding: 36px;
    }
    .index_canlder{
      padding-top: 28px;
      padding-right: 23px;
      padding-left: 23px;
      margin-top: 20px;
      background: #fff;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep.el-calendar{
  margin-top: 20px;
  .el-calendar__header{
    display: none;
  }
  .el-calendar__body{
    padding: 0;
    thead{
      background: #F3F9FF;
      color: #262C33;
    }
  }
}
::v-deep.el-input{
  .el-input__inner input::-webkit-outer-spin-button,
  .el-input__inner input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .el-input__inner input[type="number"]{
    background: #f0c78a;
    -moz-appearance: textfield;
  }
}
</style>
